import { FA_BRAND, FP_BRAND, OC_BRAND, TWE_BRAND } from "@/constants/brands";
import { BRAZIL_LOCALE } from "@/constants/countries";

const defaultConfig = {
  showSocials: true,
};

export const localisedConfig = new Map([
  [
    FA_BRAND,
    {
      [BRAZIL_LOCALE]: {
        showSocials: false,
      },
      default: defaultConfig,
    },
  ],
  [
    FP_BRAND,
    {
      default: defaultConfig,
    },
  ],
  [
    TWE_BRAND,
    {
      default: defaultConfig,
    },
  ],
  [
    OC_BRAND,
    {
      default: defaultConfig,
    },
  ],
]);
