import { useRouter } from "next/router";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { hyphenatedToTitleCase } from "@/lib/string";
import {
  alterBreadcrumbs,
  routeArrToBreadcrumbs,
  routeToArray,
} from "@/lib/array";
import { getActiveBrand } from "@/lib/brand";
import { getLocalisedConfig } from "@/lib/geo";
import AngleRightIcon from "../icons/AngleRightIcon";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";
import styles from "./Breadcrumbs.module.scss";
import { localisedConfig } from "./localisedConfig";

export interface BreadcrumbAlteration {
  // zero index, with zero being first breadcrumb after home
  position: number;
  remove?: boolean;
  updateUrl?: string;
}

export interface Breadcrumb {
  text: string;
  url: string;
}

interface BreadcrumbsProps {
  breadcrumbsToEdit?: BreadcrumbAlteration[];
  region?: string | null;
}

export const Breadcrumbs = ({
  breadcrumbsToEdit,
  region,
}: BreadcrumbsProps): ReactElement | null => {
  const brand = getActiveBrand();
  const [routes, setRoutes] = useState<Breadcrumb[]>([]);
  const router = useRouter();
  const brandConfig = localisedConfig.get(brand);
  if (!brandConfig) {
    throw new Error(`No config found for brand ${brand}`);
  }
  const contentConfig = getLocalisedConfig({
    locale: region ?? "",
    fallbackKey: "default",
    localisedConfig: brandConfig,
  });

  useEffect(() => {
    const splitRouteArr = routeToArray(router.asPath, region);
    const breadCrumbsFromPath: Breadcrumb[] =
      routeArrToBreadcrumbs(splitRouteArr);
    const breadcrumbsToRender = breadcrumbsToEdit
      ? alterBreadcrumbs(breadCrumbsFromPath, breadcrumbsToEdit)
      : breadCrumbsFromPath;

    setRoutes(breadcrumbsToRender);
  }, [router.isReady, router.asPath, breadcrumbsToEdit, region]);

  if (routes.length === 0) return null;

  return (
    <nav
      className={styles.breadcrumbsContainer}
      key={"breadcrumbsContainer"}
      data-testid="breadcrumbs"
    >
      <LinkNoPrefetch
        className={styles.breadcrumbsLink}
        href={contentConfig.homePage.href}
        key={"home"}
        aria-hidden={false}
      >
        {contentConfig.homePage.text}
      </LinkNoPrefetch>
      {routes.map((crumb, index) => {
        const url = region ? `/${region}${crumb.url}` : `${crumb.url}`;
        return (
          <Fragment key={`fragment-${index}`}>
            <AngleRightIcon
              className={`breadcrumbs__icon ${styles.breadcrumbIcon}`}
              key={`icon-${index}`}
            />
            <LinkNoPrefetch
              className={styles.breadcrumbsLink}
              href={url}
              key={crumb.text}
              aria-hidden={false}
            >
              {hyphenatedToTitleCase(crumb.text)}
            </LinkNoPrefetch>
          </Fragment>
        );
      })}
    </nav>
  );
};
