import React, { ReactElement } from "react";
import Image from "next/image";
import { MpuData } from "@/types/api/adverts";
import styles from "@/components/Mpu/Mpu.module.scss";
import { mediaUrlFromSlug } from "@/lib/media";
import { InjectedHtmlContainer } from "@/components/InnerHtml/InjectedHtmlContainer";
import { getCurrentUnixTimestampMs } from "@/lib/date";

interface MpuProps {
  isMobileDevice: boolean;
  mpu: MpuData;
}

export const Mpu = ({ isMobileDevice, mpu }: MpuProps): ReactElement => {
  return (
    <>
      {!isMobileDevice && mpu.expires_at > getCurrentUnixTimestampMs() ? (
        <div className={styles.mpu} data-testid="mpu">
          {mpu.media_type === "image" && "slug" in mpu.meta.source ? (
            <a href={mpu.meta.source.link} target="_blank" rel="noreferrer">
              <Image
                src={mediaUrlFromSlug(mpu.meta.source.slug)}
                alt={mpu.meta.source.alt}
                fill
              />
            </a>
          ) : null}

          {mpu.media_type === "code" && "code" in mpu.meta.source ? (
            <InjectedHtmlContainer html={mpu.meta.source.code} />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
