import { FA_BRAND, FP_BRAND, OC_BRAND, TWE_BRAND } from "@/constants/brands";
import { BRAZIL_LOCALE } from "@/constants/countries";
import { generateDefaultConfigForEachUsState } from "@/lib/localisedContent";
import { BrandShortNames } from "@/types/brand";

type SidebarConfig = {
  default: Record<string, string>;
} & Partial<{ [locale: string]: Record<string, string> }>;

export const US_FREEBET_TEXT = "Bonuses";
export const DEFAULT_US_SIDEBAR_HEADER = `${US_FREEBET_TEXT} & offers`;

const usDefaultConfig = generateDefaultConfigForEachUsState({
  header: DEFAULT_US_SIDEBAR_HEADER,
  freebets: US_FREEBET_TEXT,
});

export const defaultConfig = {
  "ca-on": {
    header: "Featured",
    freebets: "Free Bets",
  },
  ...usDefaultConfig,
  default: {
    header: "Free bets & offers",
    freebets: "Free Bets",
  },
};

export const localisedConfig = new Map<BrandShortNames, SidebarConfig>([
  [
    FA_BRAND,
    {
      ...defaultConfig,
      [BRAZIL_LOCALE]: {
        header: "Apostas grátis e ofertas",
        freebets: "Apostas grátis",
      },
    },
  ],
  [FP_BRAND, defaultConfig],
  [TWE_BRAND, defaultConfig],
  [OC_BRAND, defaultConfig],
]);
