import {
  LocalisedOfferDataMetaLinks,
  OfferData,
  OfferTitleAndTermsAndConditions,
} from "@/types/api/offer";
import { OffersByCategory } from "@/types/api/offerSidebar";
import { getActiveBrand } from "@/lib/brand";
import { BrandShortNames } from "@/types/brand";
import {
  BRAND_CATEGORY_HEADINGS_MAP,
  DEFAULT_OFFER_CATEGORY_HEADINGS,
} from "@/constants/staticTranslations";
import { isEmptyObject } from "./object";
import { getLocalisedContentRegionByBrand } from "./content";
import { getLocalisedConfig } from "./geo";

export const removeCategoriesWithoutOffers = (categories: OffersByCategory[]) =>
  categories.filter((category) => category.brandOffers.length > 0);

interface CategoryFormatting {
  dbName: string;
  formattedName: string;
  order: number;
}

const categoryFormattingAndSorting: CategoryFormatting[] = [
  { dbName: "freebets", formattedName: "Free Bets", order: 1 },
  { dbName: "casino", formattedName: "Casino", order: 2 },
  { dbName: "special", formattedName: "Special", order: 3 },
];

interface SortAndFormatCategoriesParams {
  categoriesWithOffers: OffersByCategory[];
  brand: BrandShortNames;
  region?: string | null;
}

export const sortAndFormatCategories = ({
  categoriesWithOffers,
  brand,
  region,
}: SortAndFormatCategoriesParams): OffersByCategory[] => {
  const sortedCategories: OffersByCategory[] = [];
  const supportedRegionOnBrand = region
    ? getLocalisedContentRegionByBrand(brand, region) === region
    : false;
  const brandConfig = BRAND_CATEGORY_HEADINGS_MAP.get(brand);
  // type guard here to ensure that region is a string rather than null
  const localisedConfig =
    brandConfig && supportedRegionOnBrand && typeof region === "string"
      ? getLocalisedConfig({
          locale: region,
          fallbackKey: "default",
          localisedConfig: brandConfig,
        })
      : DEFAULT_OFFER_CATEGORY_HEADINGS;
  categoryFormattingAndSorting.forEach((categoryFormatting) => {
    const matchingCategoryAndOffers = categoriesWithOffers.find(
      (category) => category._id === categoryFormatting.dbName
    );

    if (matchingCategoryAndOffers) {
      matchingCategoryAndOffers.formattedName =
        localisedConfig[categoryFormatting.dbName] ||
        DEFAULT_OFFER_CATEGORY_HEADINGS[categoryFormatting.dbName];
      sortedCategories.push(matchingCategoryAndOffers);
    }
  });

  return sortedCategories;
};

export const isLocalisedOffer = (offer: OfferData, locale: string): boolean => {
  const upperCaseLocale = locale.toUpperCase();
  const localisedOfferLocations = offer.meta.location;

  if (!localisedOfferLocations) return false;

  return localisedOfferLocations?.includes(upperCaseLocale);
};

export const localisedOfferTitleAndTermsAndConditions = (
  offer: OfferData,
  locale: string
): OfferTitleAndTermsAndConditions => {
  const upperCaseLocale = locale.toUpperCase();
  const { title_and_tc_locations, title, terms_and_conditions } = offer.meta;

  if (!title_and_tc_locations || !isLocalisedOffer(offer, locale))
    return { title, terms_and_conditions: terms_and_conditions.trim() };

  const offerTitle = title_and_tc_locations[upperCaseLocale].title
    ? title_and_tc_locations[upperCaseLocale].title
    : title;
  const offerTermsAndconditions = title_and_tc_locations[upperCaseLocale]
    .terms_and_conditions
    ? title_and_tc_locations[upperCaseLocale].terms_and_conditions
    : terms_and_conditions;

  return {
    title: offerTitle,
    terms_and_conditions: offerTermsAndconditions.trim(),
  };
};

export const localisedOfferLink = (
  offer: OfferData,
  locale: string,
  sidebar?: boolean
): string | undefined => {
  const brand = getActiveBrand();
  const upperCaseLocale = locale.toUpperCase();
  const { links, sidebar_links } = offer.meta;

  const localisedKey =
    `${brand}_locations` as keyof LocalisedOfferDataMetaLinks;

  if (
    sidebar &&
    sidebar_links &&
    sidebar_links[localisedKey] &&
    sidebar_links[localisedKey]![upperCaseLocale]
  ) {
    return sidebar_links[localisedKey]![upperCaseLocale];
  }

  if (sidebar && sidebar_links && sidebar_links[brand]) {
    return sidebar_links[brand];
  }

  if (links && links[localisedKey] && links[localisedKey]![upperCaseLocale]) {
    return links[localisedKey]![upperCaseLocale];
  }

  if (isEmptyObject(links)) return;

  return links[brand];
};
