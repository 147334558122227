import { ActiveSocials } from "@/types/api/socials";
import { FA_BRAND, FP_BRAND, TWE_BRAND } from "@/constants/brands";

export const activeSocialsFA: ActiveSocials = {
  x: {
    text: "@FOOTYACCUMS",
    link: "https://x.com/footyaccums",
  },
  facebook: {
    text: "FOOTYACCUMULATORS",
    link: "https://facebook.com/FootyAccumulators",
  },
  instagram: {
    text: "FOOTYACCUMS",
    link: "https://www.instagram.com/footyaccums",
  },
  youtube: {
    text: "FOOTYACCUMULATORS",
    link: "https://youtube.com/channel/UCWASUmc6_EFKuNgZxNg7K1w",
  },
};

export const activeSocialsFP: ActiveSocials = {
  x: {
    text: "@FLASHPICKS",
    link: "https://x.com/flashpicks",
  },
  instagram: {
    text: "FLASHPOLLSNOW",
    link: "https://www.instagram.com/flashpollsnow",
  },
  youtube: {
    text: "FLASHPICKS",
    link: "https://www.youtube.com/channel/UC_PZGT4xhzt19Q-sTjZt4Eg",
  },
};

export const activeSocialsTWE: ActiveSocials = {
  x: {
    text: "@TWENCLOSURE",
    link: "https://x.com/TWEnclosure",
  },
  facebook: {
    text: "THEWINNERSENCLOSURE",
    link: "https://www.facebook.com/TheWinnersEnclosure",
  },
  instagram: {
    text: "TWENCLOSURE",
    link: "https://www.instagram.com/twenclosure/",
  },
  youtube: {
    text: "FOOTYACCUMULATORS",
    link: "https://www.youtube.com/channel/UCxI8hdy0jJ1bGEfNEcYkTfw",
  },
};

export const socialsPerBrandMapping = new Map([
  [FA_BRAND, activeSocialsFA],
  [FP_BRAND, activeSocialsFP],
  [TWE_BRAND, activeSocialsTWE],
]);
