import { ReactElement } from "react";
import CardWrapper from "@/components/CardWrapper/CardWrapper";
import styles from "@/components/StayConnected/StayConnected.module.scss";
import { FacebookLogoTwo } from "@/components/icons/FacebookLogoTwo";
import { InstagramLogo } from "@/components/icons/InstagramLogo";
import { YoutubeLogo } from "@/components/icons/YoutubeLogo";
import { getActiveBrand } from "@/lib/brand";
import { Socials } from "@/types/api/socials";
import { socialsPerBrandMapping } from "@/constants/socials";
import { XLogo } from "@/components/icons/XLogo";

export const StayConnected = (): ReactElement => {
  const iconMapping = new Map<Socials, ReactElement>([
    ["x", <XLogo key="x" />],
    ["facebook", <FacebookLogoTwo key="facebook" />],
    ["instagram", <InstagramLogo key="instagram" />],
    ["youtube", <YoutubeLogo key="youtube" />],
  ]);

  const brand = getActiveBrand();
  const activeSocials = socialsPerBrandMapping.get(brand);

  return (
    <CardWrapper listHeader="Stay Connected">
      <div className={styles.socialsContent}>
        {activeSocials &&
          Object.keys(activeSocials).map((social, index) => {
            const socialMediaObject = activeSocials[social as Socials];

            return socialMediaObject ? (
              <a
                key={index}
                className={styles[social]}
                href={activeSocials[social as Socials]?.link}
              >
                {iconMapping.get(social as Socials)}
                {activeSocials[social as Socials]?.text}
              </a>
            ) : null;
          })}
      </div>
    </CardWrapper>
  );
};
