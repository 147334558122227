import { FA_BRAND, FP_BRAND, OC_BRAND, TWE_BRAND } from "@/constants/brands";
import { BRAZIL_LOCALE } from "@/constants/countries";

const defaultConfig = {
  homePage: {
    text: "Home",
    href: "/",
  },
};

export const localisedConfig = new Map([
  [
    FA_BRAND,
    {
      [BRAZIL_LOCALE]: {
        homePage: {
          text: "Início",
          href: "/br",
        },
      },
      default: defaultConfig,
    },
  ],
  [
    FP_BRAND,
    {
      default: defaultConfig,
    },
  ],
  [
    TWE_BRAND,
    {
      default: defaultConfig,
    },
  ],
  [
    OC_BRAND,
    {
      default: defaultConfig,
    },
  ],
]);
