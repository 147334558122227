import React, { ReactElement } from "react";
import Image from "next/image";
import { TramlineData } from "@/types/api/adverts";
import styles from "@/components/Tramline/Tramline.module.scss";
import { mediaUrlFromSlug } from "@/lib/media";
import { InjectedHtmlContainer } from "@/components/InnerHtml/InjectedHtmlContainer";
import { getCurrentUnixTimestampMs } from "@/lib/date";

interface TramlineProps {
  isMobileDevice: boolean;
  position: "LEFT" | "RIGHT";
  tramline: TramlineData;
}

export const Tramline = ({
  isMobileDevice,
  position,
  tramline,
}: TramlineProps): ReactElement => {
  const tramlineClassName =
    position === "LEFT" ? styles.leftTramline : styles.rightTramline;

  return (
    <div>
      {!isMobileDevice && tramline.expires_at > getCurrentUnixTimestampMs() ? (
        <div className={tramlineClassName} data-testid="tramline">
          {tramline.media_type === "image" && "slug" in tramline.meta.source ? (
            <a
              href={tramline.meta.source.link}
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={mediaUrlFromSlug(tramline.meta.source.slug)}
                alt={tramline.meta.source.alt}
                width={160}
                height={600}
              />
            </a>
          ) : null}

          {tramline.media_type === "code" && "code" in tramline.meta.source ? (
            <InjectedHtmlContainer html={tramline.meta.source.code} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
