import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { OfferCard } from "ui";
import { OffersByCategory } from "@/types/api/offerSidebar";
import {
  removeCategoriesWithoutOffers,
  sortAndFormatCategories,
} from "@/lib/offers";
import { gaEvent } from "@/lib/gtag";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { getLocalisedConfig } from "@/lib/geo";
import { FA_BRAND } from "@/constants/brands";
import { localisedConfig } from "@/components/OfferSidebar/localisedConfig";
import { getActiveBrand } from "@/lib/brand";
import styles from "./OfferSidebar.module.scss";

interface Props {
  offersByCategory: OffersByCategory[];
  region?: string | null;
}

function OfferSidebar({ offersByCategory, region }: Props) {
  const brand = getActiveBrand();
  const brandConfig = localisedConfig.get(brand);
  const categoriesWithOffers = removeCategoriesWithoutOffers(offersByCategory);
  const sortedAndFormattedCategories = sortAndFormatCategories({
    categoriesWithOffers,
    brand,
    region,
  });
  const [activeCategory, setActiveCategory] = useState(
    sortedAndFormattedCategories[0]
  );
  const showTabs = sortedAndFormattedCategories.length > 0;

  const [locale] = useLocaleFromRouter();
  if (!brandConfig) {
    throw new Error(`No sidebar config found for ${brand}`);
  }
  const [localisedConfigData, setLocalisedConfigData] = useState(
    getLocalisedConfig({
      locale: region ? region : locale,
      fallbackKey: "default",
      localisedConfig: brandConfig,
    })
  );

  useEffect(() => {
    setLocalisedConfigData(
      getLocalisedConfig({
        locale: region ? region : locale,
        fallbackKey: "default",
        localisedConfig: brandConfig,
      })
    );
  }, [brandConfig, region, locale]);

  if (!categoriesWithOffers.length) return null;

  const gaSideMenuTabSelectEvent = (selection: string): void => {
    const gaEventParams = {
      selection,
    };

    if (brand === FA_BRAND) {
      gaEvent("side_menu_tab_select", gaEventParams);
    }
  };

  const getOfferCategoryButtonText = (category: OffersByCategory): string => {
    if (category._id === "freebets") {
      return localisedConfigData["freebets"];
    }
    return category.formattedName ?? category._id;
  };

  return (
    <div className={styles.offerSidebarContainer} data-testid="sidebar">
      {sortedAndFormattedCategories.length > 1 ? (
        <h2 className={styles.offerSidebarHeader}>
          {localisedConfigData["header"]}
        </h2>
      ) : null}
      <div
        className={`${
          showTabs
            ? styles.offerSidebarBodyContainer
            : styles["offerSidebarBodyContainer--topPadding"]
        }`.trim()}
      >
        {showTabs && (
          <ul className={styles.offerCategoryUl}>
            {sortedAndFormattedCategories.map((category) => (
              <li
                key={nanoid()}
                className={
                  styles[
                    `offerCategoryLink${
                      category._id === activeCategory._id ? "--active" : ""
                    }`.trim()
                  ]
                }
                data-testid="offerCategoryLink"
              >
                <button
                  className={styles.offerCategoryButton}
                  onClick={() => {
                    gaSideMenuTabSelectEvent(
                      category.formattedName || category._id
                    );
                    setActiveCategory(category);
                  }}
                >
                  {getOfferCategoryButtonText(category)}
                </button>
              </li>
            ))}
          </ul>
        )}
        {activeCategory.brandOffers.map((brandOffer) => (
          <OfferCard offer={brandOffer.offer} key={nanoid()} sidebar={true} />
        ))}
      </div>
    </div>
  );
}

export default OfferSidebar;
